import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Input, Radio, Button, Alert } from 'antd';
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

const SmsUnsubscribe = () => {
  const { hash } = useParams();
  const {t} = useTranslation();
  const [status, setStatus] = useState('init');
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { REACT_APP_API_ENDPOINT } = process.env;

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const onFinish = (values) => {
    if (hash !== 'demo') {
      setLoading(true);
      axios(
        `${REACT_APP_API_ENDPOINT}/channel/sms/sms/${hash}/unsubscribe`,
        // `${REACT_APP_API_ENDPOINT}/contact/contact/unsubscribe/sms/${hash}`,
        {
          method: 'patch',
          headers: {
            'Content-Type': 'application/json',
          },
          data: values,
        }
      )
        .then((response) => {
          setStatus('ok');
        })
        .catch((error) => {
          setStatus('error');
          setLoading(false);
        });
    } else {
      setStatus('ok');
    }
  };

  // debugger;
  // console.log(t('form.reason'));

  return (
    <div>
      <div className="list-form">
        {status === 'ok' && (
          <Alert
            style={{ textAlign: 'center', fontSize: 20 }}
            message={t('notice.success.unsubscribe')}
            type="success"
          />
        )}
        {status === 'error' && (
          <Alert
            showIcon
            style={{ marginBottom: 30 }}
            message={t('notice.error.unsubscribe')}
            type="error"
          />
        )}
        {status !== 'ok' && (
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item name="reason" label={t('form.reason')}>
              <Radio.Group defaultValue={"stop"}>
                <Radio style={radioStyle} value="stop">
                  {t('form.reason.stop')}
                </Radio>
                <Radio style={radioStyle} value="unsolicited">
                  {t('form.reason.unsolicited')}
                </Radio>
                <Radio style={radioStyle} value="inappropriate">
                  {t('form.reason.inappropriate')}
                </Radio>
                <Radio style={radioStyle} value="spam">
                  {t('form.reason.spam')}
                </Radio>
                <Radio style={radioStyle} value="other">
                  {t('form.reason.other')}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.reason !== currentValues.reason
              }
            >
              {({ getFieldValue }) => {
                return getFieldValue('reason') === 'other' ? (
                  <Form.Item name="reasonComment" label={t('form.reason.comment')}>
                    <Input.TextArea maxLength="120" />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {t('form.button.unsubscribe')}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
      <LanguageSwitcher />
    </div>
  );
};

export default SmsUnsubscribe;
