import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import SmsUnsubscribe from './SmsUnsubscribe';
import './App.less';
import './i18n/i18n';

const RedirectToWeb = () => {
  window.location.assign('https://www.instasent.com');
}

const App = () => (
  <Router>
    <div>
      <Switch>
        <Route path="/sms/:hash">
          <SmsUnsubscribe />
        </Route>
        <Route path="*">
          <RedirectToWeb />
        </Route>
      </Switch>
    </div>
  </Router>
);

export default App;
