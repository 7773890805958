import React from 'react';
import { useTranslation } from 'react-i18next';
import {languageNames} from "./i18n/i18n";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const currentLanguage = i18n.language;

  return (
    <div className={"language-switcher"}>
      {Object.keys(languageNames).map((language) => {
        return <button className={currentLanguage === language ? 'active':''} key={language} onClick={() => changeLanguage(language)}>{languageNames[language]}</button>
      })}
    </div>
  );
}

export default LanguageSwitcher;
