import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './translations_en.json';
import translationES from './translations_es.json';
import translationPT from './translations_pt.json';
import translationIT from './translations_it.json';
import translationFR from './translations_fr.json';
import translationDE from './translations_de.json';
import translationCA from './translations_ca.json';
import translationGL from './translations_gl.json';
import translationEU from './translations_eu.json';
import translationZH from './translations_zh.json';
import translationRU from './translations_ru.json';
import translationAR from './translations_ar.json';
import translationHI from './translations_hi.json';
import translationJA from './translations_ja.json';
import translationKO from './translations_ko.json';

export const languageNames = {
  en: 'English',
  es: 'Español',
  pt: 'Português',
  it: 'Italiano',
  fr: 'Français',
  de: 'Deutsch',
  ru: 'Русский',
  zh: '中文',
  ar: 'العربية',
  hi: 'हिन्दी',
  ja: '日本語',
  ko: '한국어',
  ca: 'Català',
  gl: 'Galego',
  eu: 'Euskara',
};

const resources = {
  en: {
    translations: translationEN
  },
  es: {
    translations: translationES
  },
  pt: {
    translations: translationPT
  },
  it: {
    translations: translationIT
  },
  fr: {
    translations: translationFR
  },
  de: {
    translations: translationDE
  },
  ca: {
    translations: translationCA
  },
  gl: {
    translations: translationGL
  },
  eu: {
    translations: translationEU
  },
  zh: {
    translations: translationZH
  },
  ru: {
    translations: translationRU
  },
  ar: {
    translations: translationAR
  },
  hi: {
    translations: translationHI
  },
  ja: {
    translations: translationJA
  },
  ko: {
    translations: translationKO
  },
};

i18n
  // Connect with React
  .use(initReactI18next)
  // Automatic language detection
  .use(LanguageDetector)
  // Pass in the resources
  .init({
    resources,
    fallbackLng: 'en', // use en if detected lng is not available

    // debug: true,
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })
  .then((obj) => {
    // console.log('i18next is ready');
  })
  .catch(err => console.error('i18next initialization error:', err));

export default i18n;